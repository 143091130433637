import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {CampContext} from "../../context/CampContext";
import moment from 'moment'
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import Skeleton from '@material-ui/lab/Skeleton';

import './Camp.scss'
import axios from "axios";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
}));

export default function Booking(props) {
    const classes = useStyles();
    let { dispatch } = useContext(CampContext);
    let [loading, setLoading] = useState(true);
    let [bookings, setBookings] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            await axios({
                // url: 'http://handball-ferienschule.docker:8086/graphql',
                url: 'https://www.handball-ferienschule.de/graphql',
                // url: 'https://www.handball-ferienschule.de/v1/camps',
                method: 'post',
                data: {
                    query: `
                        query {
                            readBookings(ID: ${props.match.params.bookingID}) {
                                Firstname
                                Lastname
                                Birthday
                                TshirtSize
                                Street
                                ZIP
                                City
                                Phone
                                Email
                                Club
                                AdditionalMessage
                            }
                        }
                    `
                }
                // withCredentials: true
            }).then(result => {
                setBookings(result.data.data.readBookings)
                setLoading(false)
            }).catch(error => {
                if (error.response && error.response.status === 401) {
                    dispatch({ type:'reset', payload: null})
                }
            });
        };

        if (window.localStorage.getItem('token')) {
            fetchData();
        }
    }, []);

    if (loading) {
        return (
            <Grid container spacing={3}>
                {[12,4,4,4,12].map((grid, index) => {
                    return (
                        <Grid item xs={12} sm={grid} key={`grid-${index}`}>
                            {index === 0 ?
                                <Avatar className={classes.avatar}
                                        style={{width: '60px', height: '60px', margin: '0 auto 20px'}}>
                                    <PersonIcon style={{fontSize: '30px'}}/>
                                </Avatar>
                                : null
                            }
                            <Paper className={classes.paper}>
                                <h1><Skeleton width="40%" height={38} /></h1>
                                <p><Skeleton width="30%"/></p>
                                <p><Skeleton width="30%"/></p>
                            </Paper>
                        </Grid>
                    )})
                }
            </Grid>
        )
    }

    if (bookings.length) {
        const booking = bookings[0]
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Avatar className={classes.avatar} style={{width: '60px', height: '60px', margin: '0 auto 20px'}}>
                        <PersonIcon style={{fontSize: '30px'}} />
                    </Avatar>
                    <Paper className={classes.paper}>
                        <h1>{booking.Firstname} {booking.Lastname}</h1>
                        <p>{moment(booking.Birthday).format('DD.MM.YYYY')}</p>
                        <p>T-Shirt: {booking.TshirtSize}</p>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper className={classes.paper}>
                        <h4>Adresse</h4>
                        <div className={'booking-item__row'}>{booking.Street}</div>
                        <div className={'booking-item__row'}>{booking.ZIP} {booking.City}</div>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper className={classes.paper}>
                        <h4>Kontakt</h4>
                        <div className={'booking-item__row'}><a href={`tel:${booking.Phone}`}>{booking.Phone}</a></div>
                        <div className={'booking-item__row'}>{booking.Email}</div>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper className={classes.paper}>
                        <h4>Verein</h4>
                        <div className={'booking-item__row'}>{booking.Club}</div>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <h4>Nachricht</h4>
                        <div className={'booking-item__row'}>{booking.AdditionalMessage}</div>
                    </Paper>
                </Grid>
            </Grid>
        );
    }

    return null;
}
