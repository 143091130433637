import React, {useContext, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import {CampContext} from "../../context/CampContext";
import moment from 'moment'

import './Camp.scss'
import axios from "axios";
import Skeleton from "@material-ui/lab/Skeleton/Skeleton";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
}));

export default function SingleCamp(props) {
    const classes = useStyles();
    let { dispatch } = useContext(CampContext);
    let [loading, setLoading] = useState(true);
    let [camps, setCamps] = useState([]);
    let [bookings, setBookings] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            await axios({
                // url: 'http://handball-ferienschule.docker:8086/graphql',
                url: 'https://www.handball-ferienschule.de/graphql',
                // url: 'https://www.handball-ferienschule.de/v1/camps',
                method: 'post',
                data: {
                    query: `
                        query {
                          readCamps(ID: ${props.match.params.campID}) {
                            ID
                            Title
                            Begin
                            End
                            Bookings {
                              edges {
                                node {
                                  ID
                                  Firstname
                                  Lastname
                                }
                              }
                            }
                          }
                        }
                    `
                }
                // withCredentials: true
            }).then(result => {
                setCamps(result.data.data.readCamps);
                let tmpbookings = []
                result.data.data.readCamps[0].Bookings.edges.map(item => {
                    tmpbookings.push(item.node)
                    return null
                })
                setBookings(tmpbookings)
                setLoading(false)
            }).catch(error => {
                if (error.response && error.response.status === 401) {
                    dispatch({ type:'reset', payload: null})
                }
            });
        };

        if (window.localStorage.getItem('token')) {
            fetchData();
        }
    }, []);

    if (loading) {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <h1><Skeleton width="40%" height={38} /></h1>
                        <div><Skeleton width="30%" /></div>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <List>
                        <Divider key={`devider`} />
                        {Array(10).fill(1).map((dummy, index) => {
                            return ([
                                <ListItem key={`item-${index}`}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <PersonIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={<Skeleton width="40%" />} secondary={''} />
                                </ListItem>,
                                <Divider key={`devider-${index}`} />
                            ])
                        })}
                    </List>
                </Grid>
            </Grid>
        );
    }

    if (camps.length && moment().format('YYYY') === moment(camps[0].Begin).format('YYYY')) {
        const begin = moment(camps[0].Begin).format('DD.MM.YYYY')
        const end = moment(camps[0].End).format('DD.MM.YYYY')

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} key={`camps-${camps[0].ID}`}>
                    <Paper className={classes.paper}>
                        <h1>{camps[0].Title}</h1>
                        <div>{begin} - {end}</div>
                    </Paper>
                </Grid>
                <Grid item xs={12} key={`bookings`}>
                    <List>
                        <Divider key={`devider`} />
                        {bookings.map(booking => {
                            return ([
                                <ListItem
                                    key={`booking-${booking.ID}`}
                                    button
                                    component={React.forwardRef((props, ref) => <Link to={`/camp/person/${booking.ID}`} {...props} />)}
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <PersonIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={`${booking.Lastname}, ${booking.Firstname}`} secondary={''} />
                                </ListItem>,
                                <Divider key={`devider-${booking.ID}`} />
                            ])
                        })}
                    </List>
                </Grid>
            </Grid>
        );
    }

    return null;
}
