import React, {useContext} from 'react';
import {Redirect} from 'react-router';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {CampContext} from "../../context/CampContext";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        color: '#333',
        backgroundColor: '#fff',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: '#e10707',
    },
}));

export default function Login() {
    let {dispatch} = useContext(CampContext)

    const handleLogin = event => {
        event.preventDefault()

        const fetchData = async () => {
            const hash = btoa(`${event.target.email.value}:${event.target.password.value}`)
            const result = await axios({
                // url: 'http://handball-ferienschule.docker:8086/v1/getToken',
                url: 'https://www.handball-ferienschule.de/v1/getToken',
                method: 'get',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'Authorization' : `Basic ${hash}`,
                },
                responseType: 'json',
            });

            console.log(result.data);
            if (result.data) {
                dispatch({
                    type: "fetchLogin", payload: result.data
                })
            } else {
                dispatch({
                    type: "reset", payload: null
                })
            }
        };

        fetchData()
    }

    const classes = useStyles()

    return (
        <Container component="main" maxWidth="xs">
            {console.log(window.localStorage.getItem('token'))}
            {window.localStorage.getItem('token') ? <Redirect to={'/'} /> : null}
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Anmeldung
                </Typography>
                <form className={classes.form} onSubmit={handleLogin} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Addresse"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Passwort"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    {/*<FormControlLabel*/}
                        {/*control={<Checkbox value="remember" color="primary" />}*/}
                        {/*label="Remember me"*/}
                    {/*/>*/}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Anmelden
                    </Button>
                    {/*<Grid container>*/}
                        {/*<Grid item xs>*/}
                            {/*<Link href="#" variant="body2">*/}
                                {/*Forgot password?*/}
                            {/*</Link>*/}
                        {/*</Grid>*/}
                        {/*<Grid item>*/}
                            {/*<Link href="#" variant="body2">*/}
                                {/*{"Don't have an account? Sign Up"}*/}
                            {/*</Link>*/}
                        {/*</Grid>*/}
                    {/*</Grid>*/}
                </form>
            </div>
        </Container>
    )
}
