import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter, Route, Switch} from 'react-router-dom'
import './index.css';

import App from './App';
import Camp from './components/camp/Camp';
import Bookings from './components/bookings/Bookings';
import {CampContextProvider} from './context/CampContext';

import * as serviceWorker from './serviceWorker';
import CheckIn from "./components/checkin/CheckIn";
import Login from "./components/login/Login";
import SingleCamp from "./components/camp/SingleCamp";
import Booking from "./components/camp/Booking";

ReactDOM.render(
    <CampContextProvider>
        <HashRouter>
            <Switch>
                <Route path='/camp/person/:bookingID' render={props => {return <App {...props} title={'Buchung bearbeiten'} content={ <Booking match={props.match} /> }/>}} />
                <Route path='/camp/:campID' render={props => {return <App {...props} title={'Camp bearbeiten'} content={ <SingleCamp match={props.match} /> }/>}} />
                <Route exact path='/camp' render={props => {return <App {...props} title={'Camp Liste'} content={ <Camp {...props} /> }/>}} />
                <Route exact path='/buchung' render={props => {return <App {...props} title={'Buchungen'} content={ <Bookings /> }/>}} />
                <Route exact path='/teilnehmer' render={props => {return <App {...props} title={'Teilnehmer'} content={ null }/>}} />
                <Route exact path='/checkin' render={props => {return <App {...props} title={'Check In'} content={ <CheckIn /> }/>}} />
                <Route exact path='/login' render={props => {return <Login {...props} />}} />
                <Route exact path='/' render={props => {return <App {...props} title={'Start'} content={ null }/>}} />
            </Switch>
        </HashRouter>
    </CampContextProvider>
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
