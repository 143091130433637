import React, {useContext} from 'react';
import {CampContext} from "../../context/CampContext";

import './Bookings.scss'
import BookingItem from "./BookingItem";

export default function Bookings() {
    let { state, dispatch } = useContext(CampContext);

    let bookingList = []
    state.bookings.forEach((booking, index) => {
        bookingList.push(
            <BookingItem key={index} item={booking}></BookingItem>
        )
    })

    return (
        <div className="bookings">
            <div className="bookings__outer">
                <div className="bookings__middle">
                    <div className="bookings__inner">
                        <h1>Buchungen</h1>
                        {bookingList}
                    </div>
                </div>
            </div>
        </div>
    );
}
