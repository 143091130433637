import React, {useContext, useEffect, useState} from 'react';
import {Redirect} from 'react-router';
import {CampContext} from "./context/CampContext";

import './App.scss';
import Navigation from "./components/navigation/Navigation";
import {makeStyles} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import AppBar from "@material-ui/core/AppBar/AppBar";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import Typography from "@material-ui/core/Typography/Typography";


const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));


function App(props) {
// console.log(props.match.params)
    let [open, setOpen] = useState(false);
    let [isLoggedIn, setLoggedIn] = useState(false);
    let { state, dispatch } = useContext(CampContext);

    const username = 'janedoe'
    const password = 's00pers3cret'
    const basicString = btoa(`${username}:${password}`)

    const requestHeaders = {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        // 'Authorization' : window.localStorage.getItem('token') ? `Basic ${window.localStorage.getItem('token')}` : null,
    }

    // useEffect(() => {
    //     const fetchData = async () => {
    //         const result = await axios({
    //             url: 'http://handball-ferienschule.docker:8086/v1/bookings',
    //             // url: 'https://www.handball-ferienschule.de/v1/bookings',
    //             method: 'get',
    //             headers: requestHeaders,
    //             responseType: 'json',
    //             // withCredentials: true
    //         }).then(result => {
    //             fetchBookings(result.data);
    //         }).catch(error => {
    //             if (error.response && error.response.status === 401) {
    //                 dispatch({ type:'reset', payload: null})
    //             }
    //         });
    //     };
    //
    //     if (window.localStorage.getItem('token')) {
    //         fetchData();
    //     }
    // }, []);

    useEffect(() => {
        setLoggedIn(window.localStorage.getItem('token'))
        // console.log(window.localStorage.getItem('token'))
    })

    let fetchBookings = bookings => dispatch({ type: "fetchBookings", payload: bookings });
    let fetchCamps = camps => dispatch({ type: "fetchCamps", payload: camps });

    const classes = useStyles();


    function handleDrawerOpen() {
        setOpen(true);
    }

    function handleDrawerClose() {
        setOpen(false);
    }

    function handleLogout() {
        // document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:01 UTC;';
        dispatch({ type: 'reset', payload: null})
    }

    return (
        <div className={classes.root}>
            {!window.localStorage.getItem('token') ? <Redirect to={'/login'} /> : null}
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
                style={{
                    backgroundColor: '#e10707',
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap style={{color: '#fff'}}>
                        {props.title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Navigation classes={classes} open={open} handleDrawerClose={handleDrawerClose} handleLogout={handleLogout} />
            <main className={clsx(classes.content, {
                [classes.contentShift]: open,
                'main-content': true
            })}
            >
                <div className={classes.drawerHeader} >
                    {props.content}
                </div>
            </main>
        </div>
    );
}

export default App;
