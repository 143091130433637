import React, {useContext, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import EditIcon from '@material-ui/icons/Edit';
import Skeleton from '@material-ui/lab/Skeleton';
import {CampContext} from "../../context/CampContext";
import moment from 'moment'

import './Camp.scss'
import axios from "axios";
import Divider from "@material-ui/core/Divider/Divider";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function Camp(props) {
    const classes = useStyles();
    let { dispatch } = useContext(CampContext);
    let [loading, setLoading] = useState(true);
    let [camps, setCamps] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            await axios({
                // url: 'http://handball-ferienschule.docker:8086/graphql',
                url: 'https://www.handball-ferienschule.de/graphql',
                // url: 'https://www.handball-ferienschule.de/v1/camps',
                method: 'post',
                data: {
                    query: `
                        query {
                          readCamps {
                            ID
                            Title
                            Begin
                            End
                          }
                        }
                    `
                }
                // withCredentials: true
            }).then(result => {
                setCamps(result.data.data.readCamps)
                setLoading(false)
            }).catch(error => {
                if (error.response && error.response.status === 401) {
                    dispatch({ type:'reset', payload: null})
                }
            });
        };

        if (window.localStorage.getItem('token')) {
            fetchData();
        }
    }, []);

    if (loading) {
        return (
            <Grid container spacing={2}>
                <List className={classes.root}>
                    <Divider key={`devider`} />
                    {console.log(Array(10))}
                    {Array(10).fill(1).map((dummy, index) => {
                        console.log(dummy, index)
                        return ([
                            <ListItem key={`item-${index}`}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <ImageIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={<Skeleton width={'40%'} />} secondary={<Skeleton width={'30%'} />} />
                                <IconButton edge="end" aria-label="edit">
                                    <EditIcon />
                                </IconButton>
                            </ListItem>,
                            <Divider key={`devider-${index}`} />
                        ])
                    })}
                </List>
            </Grid>
        )
    }

    return (
        <Grid container spacing={2}>
            <List className={classes.root}>
                <Divider key={`devider`} />
                {camps.map((camp) => {
                    if (camp, moment().format('YYYY') === moment(camp.Begin).format('YYYY')) {
                        const begin = moment(camp.Begin).format('DD.MM.YYYY')
                        const end = moment(camp.End).format('DD.MM.YYYY')
                        const date = `${begin} - ${end}`
                        return ([
                            <ListItem
                                key={`camp-${camp.ID}`}
                                button
                                component={React.forwardRef((props, ref) => <Link to={`/camp/${camp.ID}`} {...props} />)}
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                        <ImageIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={camp.Title} secondary={date} />
                                    <IconButton edge="end" aria-label="edit">
                                        <EditIcon />
                                    </IconButton>
                            </ListItem>,
                            <Divider key={`devider-${camp.ID}`} />
                        ])
                    }

                    return null
                })}
            </List>
        </Grid>
    );
}
