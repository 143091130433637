import React from 'react';
import {Link} from "react-router-dom";
import {useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import HomeIcon from '@material-ui/icons/Home';
import RefreshIcon from '@material-ui/icons/Refresh';
import * as serviceWorker from './../../serviceWorker';

export default function Navigation(props) {

    const theme = useTheme();
    // let {state, dispatch} = useContext(CampContext)

    const linkStyle = {
        textDecoration: 'none',
        color: 'rgba(0, 0, 0, 0.54)'
    }

    return (
        <Drawer
            className={props.classes.drawer}
            variant="persistent"
            anchor="left"
            open={props.open}
            classes={{
                paper: props.classes.drawerPaper,
            }}
        >
            <div className={props.classes.drawerHeader}>
                <IconButton onClick={props.handleDrawerClose}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </div>
            <Divider />
            <List>
                <Link to={'/'} style={linkStyle} onClick={props.handleDrawerClose}>
                    <ListItem button key={'Start'}>
                        <ListItemIcon><HomeIcon /></ListItemIcon>
                        <ListItemText primary={'Start'} />
                    </ListItem>
                </Link>
                <Link to={'/camp'} style={linkStyle} onClick={props.handleDrawerClose}>
                    <ListItem button key={'Camps'}>
                        <ListItemIcon><BeachAccessIcon /></ListItemIcon>
                        <ListItemText primary={'Camps'} />
                    </ListItem>
                </Link>
                <Link to={'/checkin'} style={linkStyle} onClick={props.handleDrawerClose}>
                    <ListItem button key={'Checkin'}>
                        <ListItemIcon><BeachAccessIcon /></ListItemIcon>
                        <ListItemText primary={'Checkin'} />
                    </ListItem>
                </Link>
                <a href={'reload'} style={linkStyle} onClick={e => {
                    e.preventDefault()
                    serviceWorker.forceSWupdate()
                }}>
                    <ListItem button key={'Refresh'}>
                        <ListItemIcon><RefreshIcon /></ListItemIcon>
                        <ListItemText primary={'Neu laden'} />
                    </ListItem>
                </a>
                <a style={linkStyle} onClick={e => {
                    e.preventDefault()
                    window.location.reload(true)
                }}>
                    <ListItem button key={'Refresh'}>
                        <ListItemIcon><RefreshIcon /></ListItemIcon>
                        <ListItemText primary={'Logout'} />
                    </ListItem>
                </a>
            </List>
        </Drawer>
    );
}
