import React, {useState} from 'react';

import './BookingItem.scss'

export default function BookingItem(props) {
    let [ isOpen, toggle ] = useState(true)

    const item = props.item

    return (
        <div className="booking-item" onClick={() => toggle(!isOpen)}>
            {isOpen ? <OpenBox item={item}></OpenBox> : <ClosedBox item={item}></ClosedBox>}
        </div>
    );
}

const ClosedBox = props => {
    const item = props.item
    return <div>{item.Firstname} {item.Lastname}</div>
}

const OpenBox = props => {
    const item = props.item
    return (
        <div>
            <div className={'booking-item__column'}>
                TODO Zahlung
            </div>
            <div className={'booking-item__column'}>
                <div className={'booking-item__row'}><strong>{item.Firstname} {item.Lastname}</strong></div>
                <div className={'booking-item__row'}>{item.Street}</div>
                <div className={'booking-item__row'}>{item.ZIP} {item.City}</div>
            </div>
            <div className={'booking-item__column'}>
                <div className={'booking-item__row'}>{item.Phone}</div>
                <div className={'booking-item__row'}>{item.Mobile}</div>
                <div className={'booking-item__row'}>{item.Email}</div>
            </div>
        </div>
    )
}
