import React from "react";

let CampContext = React.createContext();

let initialState = {
    token: '',
    camps: [],
    bookings: []
};

let reducer = (state, action) => {
    console.log(action)
    switch (action.type) {
        case "reset":
            return initialState;
        case "fetchLogin":
            window.localStorage.setItem('token', action.payload.Token);
            return { ...state, token: action.payload.Token };
        case "fetchBookings":
            const sortedBookings = action.payload.sort((a, b) => a.Lastname < b.Lastname ? -1 : 1)
            return { ...state, bookings: sortedBookings };
        case "fetchCamps":
            return { ...state, camps: action.payload };
        default:
    }
};

function CampContextProvider(props) {
    let [state, dispatch] = React.useReducer(reducer, initialState);
    let value = { state, dispatch };

    return (
        <CampContext.Provider value={value}>{props.children}</CampContext.Provider>
    );
}

let CampContextConsumer = CampContext.Consumer;

export { CampContext, CampContextProvider, CampContextConsumer };
